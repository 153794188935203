import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ArticlesComponent from "../components/articles";
import "../assets/css/main.css";
import Newsletter from "../components/newsletter";
import Cta from "../components/cta";


const BlogPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="relative bg-white pt-12 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              From the blog
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            </p>
          </div>
          <ArticlesComponent articles={data.allStrapiArticle.edges} />
        </div>
      </div>
      <Newsletter/>
      <Cta />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
    allStrapiArticle(filter: { status: { eq: "published" } }, sort: {fields: publishedAt, order: DESC}) {
      edges {
        node {
          strapiId
          slug
          title
          description
          publishedAt
          read_min
          category {
            name
          }
          image {
            childImageSharp {
              gatsbyImageData(width: 800, height: 500)
            }
          }
          author {
            name
            picture {
              childImageSharp {
                gatsbyImageData(width: 30, height: 30)
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
